import * as React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import BlogPosts from "../components/BlogPosts"
import Newsletter from "../components/Newsletter"

const BlogPage = () => (
  <Layout>
    <SEO title="Blog" />
    <BlogPosts />
    <Newsletter />
  </Layout>
)

export default BlogPage
