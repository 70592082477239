import React from "react";

function Newsletter() {
  return (
    <section className="py-20 bg-blue-600">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap justify-center items-center -mx-3">
          <div className="w-full lg:w-1/2 px-3 mb-8 text-center lg:text-left">
            <h2 className="text-3xl mb-4 text-white lg:text-4xl font-bold font-heading">
              <span>Lorem ipsum</span>
              <span className="text-blue-200">dolor sit</span>
              <span>amet, consectetur adipiscing.</span>
            </h2>
            <p className="text-blueGray-200">
              Integer felis tellus, rhoncus ac pulvinar vitae.
            </p>
          </div>
          <div className="w-full lg:w-1/2 px-3">
            <div className="flex flex-wrap max-w-lg mx-auto">
              <div className="flex w-full md:w-2/3 px-3 mb-3 md:mb-0 md:mr-6 bg-blue-600 border border-blue-500 rounded">
                <svg
                  className="h-6 w-6 my-auto text-blue-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <input
                  className="w-full pl-3 py-4 text-xs text-white placeholder-white font-semibold leading-none bg-blue-600 outline-none"
                  type="text"
                  placeholder="Type your e-mail"
                />
              </div>
              <button
                className="w-full md:w-auto py-4 px-8 text-xs text-white text-blue-800 hover:text-white font-semibold leading-none border hover:border-blue-500 bg-white hover:bg-blue-600 rounded transition duration-300 ease-in-out"
                type="submit"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;